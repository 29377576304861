import { lazy } from 'react';

export interface ProtectedRouteInterface {
  path: string;
  name: string;
  component: React.LazyExoticComponent<React.ComponentType<any>>;
}

export const protectedRoutes: ProtectedRouteInterface[] = [
  {
    path: '/chat',
    name: 'Twin Chat',
    component: lazy(() => import('@/pages/chat/Chat')),
  },
  {
    path: '/chat/:twinName',
    name: 'Twin Chat',
    component: lazy(() => import('@/pages/chat/Chat')),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: lazy(() => import('@/pages/profile/Profile')),
  },
  {
    path: '/my-twin',
    name: 'My Twin',
    component: lazy(() => import('@/pages/my-twin')),
  },
  {
    path: '/my-twin/landing',
    name: 'Get Twin',
    component: lazy(() => import('@/pages/my-twin/twin-landing')),
  },
  {
    path: '/checkout/:id',
    name: 'Checkout Product',
    component: lazy(() => import('@/pages/payment/checkout')),
  },

  {
    path: '/subscribed-twins',
    name: 'Subscribed Twins',
    component: lazy(
      () => import('@/pages/explore-twins/subscribedTwins/SubscribedTwins')
    ),
  },
  {
    path: '/buy-points',
    name: 'Buy Points',
    component: lazy(() => import('@/pages/buy-points-new/BuyPoints')),
  },
  {
    path: '/twin-studio',
    name: 'Twin Studio',
    component: lazy(() => import('@/pages/twin-studio/TwinStudio')),
  },
  {
    path: '/twin-studio/collection',
    name: 'My Collection',
    component: lazy(() => import('@/pages/twin-studio/MyCollection')),
  },
  {
    path: '/my-twin/update',
    name: 'Update Twin',
    component: lazy(() => import('@/pages/my-twin/update-twin/UpdateTwin')),
  },
  {
    path: '/my-twin/plan',
    name: 'Manage Plan',
    component: lazy(
      () =>
        import(
          '@/pages/my-twin/twin-dashboard/components/user-twin-plan/ManagePlan'
        )
    ),
  },
];

export const protectedRouteNames = protectedRoutes.map((route) => route.name);

export const protectedRoutePaths = protectedRoutes.map((route) => route.path);

export default protectedRoutes;
